import React from 'react'
import {graphql} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = ({ ...props}) => {
  const { t } = useTranslation()
  return (
    <Layout {...props}>
      <Seo title={t('Contact')} />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="Get in touch"
            subheader='We are always happy to hear from you and respond to your queries.' 
          />
          <Divider />
          <ContactForm />
        </Main>
        <Sidebar>
          <ContactInfo />
          <Divider />
          <Commitment />
        </Sidebar>
      </Stack>
    </Layout>
  )
  }

export default PageContact

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`