import React from 'react'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => {
  const { t } = useTranslation()
  return (
  <Section aside title={t('Our Commitment')} {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        <Trans>At SOP we take our commitment to our clients and partners seriously. If you need our help with your project, or have questions, please do not hesitate to contact us</Trans>.
      </Text>
    </Card>
  </Section>
  )
}

export default Commitment
